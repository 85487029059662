.field-input {
	width: 49%;
}

.address-input {
	width: 100%;
}

.content-wrapper {
	background-color: #e7eef8;
	height: 100%;
}

.form-container {
	width: 72%;
	border-size: 2px;
	margin: auto;
	background-color: #6c5dd31a;
	border-radius: 16px;
	overflow: hidden;
}

@media (max-width: 992px) {
	.field-input {
		width: 100%;
	}

	.content-wrapper {
		background: none;
	}
}

@media (max-width: 576px) {
	.form-container {
		width: 100%;
	}
}
