//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.list-group-flush.list-group-horizontal > .list-group-item {
	border-bottom-width: 1px;
	border-top-width: 0;
}

.list-group-flush.list-group-horizontal:last-child > .list-group-item {
	border-bottom-width: 0;
}

.list-group-flush.list-group-horizontal.list-item-2 > .list-group-item {
	min-width: 50%;
}

.custom-calender .rmdp-arrow-container {
	display: block !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 0.833em !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 5px !important;
    background: #EFF2F7;
}

.custom-calender .rmdp-month-picker {
	padding: 15px 5px !important;
}

.custom-calender .rmdp-day span {
	font-size: 13px !important;
}

.custom-calender .rmdp-day.rmdp-today span:not(:hover) {
	background-color: unset !important;
	color: unset !important
}

.custom-calender .rmdp-day.rmdp-range,
.custom-calender .rmdp-day.rmdp-range span, 
.custom-calender .rmdp-day.rmdp-selected span {
	background-color: rgb(61, 145, 255) !important;
}

.custom-calender .rmdp-header {
	line-height: unset !important;
}

.custom-calender .rmdp-header-values {
	padding: 1rem;
}

.custom-calender {
	border: unset !important;
}


.custom-header-calender {
	margin: 0;
	padding: 0.833em;
}

.custom-header-calender .rdrDateDisplayItemActive {
	border-color: rgb(61, 145, 255);
}